.example .pagination {
	margin-bottom: 0;
}

.pagination {
	display: -ms-flexbox;
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 3px;
}

.pagination-lg {
	.page-link {
		padding: .75rem 1.5rem;
		font-size: 1.125rem;
		line-height: 1.5;
	}
	.page-item {
		&:first-child .page-link {
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
		}
		&:last-child .page-link {
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}
	}
}

.pagination-sm {
	.page-link {
		padding: .25rem .5rem;
		font-size: .875rem;
		line-height: 1.5;
	}
	.page-item {
		&:first-child .page-link {
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
		}
		&:last-child .page-link {
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}
	}
}

ul.inbox-pagination {
	float: right;
	li {
		float: left;
	}
}

.pagination-simple .page-item {
	.page-link {
		background: 0 0;
		border: none;
	}
	&.active .page-link {
		color: #3d4e67;
		font-weight: 700;
	}
}

.pagination-pager {
	.page-prev {
		margin-right: auto;
	}
	.page-next {
		margin-left: auto;
	}
}