.count {
	font-size: 37px;
	margin-top: 10px;
	margin-bottom: 10px;
}


/** Count Down **/

#count-down {
	margin: 3rem;
	.clock-presenter {
		height: 80px;
		padding: 0;
		text-align: center;
		.digit {
			margin-top: 20px;
			font-size: 3rem;
			line-height: 46px;
			height: 50px;
			padding: 0;
			display: inline-block;
			overflow: hidden;
			text-align: center;
			position: relative;
			cursor: default;
			color: $white;
			background-size: cover;
			border-radius: 3px;
			background: transparent !important;
		}
		.note {
			position: relative;
		        margin-top: 15px;
			cursor: default;
			font-size: 16px;
			opacity: .7;
		}
	}
}

@media (max-width: 992px) {
	#count-down .clock-presenter .digit {
		font-size: 42px;
	}
}

@media (max-width: 767px) {
	#count-down {
		.clock-presenter {
			width: 50%;
			float: left;
			.digit {
				font-size: 36px;
			}
		}
		.hours_dash {
			border-right: none;
		}
	}
}

#count-down1 .clock-presenter {
	padding: 0;
	text-align: center;
	.digit {
		font-size: 20px;
		line-height: 40px;
		height: 40px;
		display: inline-block;
		overflow: hidden;
		text-align: center;
		position: relative;
		cursor: default;
		background: $white;
		color: $black;
		border-radius: 3px;
	}
	.note {
		position: relative;
		margin-bottom: 0;
		cursor: default;
		font-size: 14px;
		opacity: .7;
	}
}

@media (max-width: 992px) {
	#count-down1 .clock-presenter .digit {
		font-size: 16px;
	}
}

@media (max-width: 767px) {
	#count-down1 {
		.clock-presenter {
			width: 50%;
			float: left;
			.digit {
				font-size: 18px;
			}
		}
		.hours_dash {
			border-right: none;
		}
	}
}

#count-down {
	position: relative;
	text-align: center;
	display: inline-block;
	margin: 50px auto;
	.clock-presenter {
		height: 80px;
		padding: 0px;
		text-align: center;
		.digit {
			margin-top: 10px;
			font-size: 1.5rem;
			line-height: 60px;
			height: 60px;
			display: inline-block;
			overflow: hidden;
			text-align: center;
			position: relative;
			cursor: default;
			font-weight: 600;
			border-radius: 3px;
		}
		width: 80px;
		height: 80px;
		float: left;
		margin: 0 20px 0 0;
		border-radius: 50%;
		background-size: cover;
		position: relative;
		background: $black-7;
		box-shadow: 0 0 0 rgb(17, 5, 150);
		animation: pulse2 2s infinite;
		&:before {
			content: '';
			position: absolute;
			background: rgba(17, 5, 150, 0.75);
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			display: block;
			border-radius: 50%;
		}
		.note {
			position: relative;
			margin-bottom: 0px;
			cursor: default;
			font-size: 16px;
			opacity: 0.7;
		}
	}
}


/** Count Down **/