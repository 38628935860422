.chat {
	list-style: none;
	background: 0 0;
	margin: 0;
	padding: 0 0 0;
	li {
		padding: 15px;
		overflow: hidden;
		display: flex;
	}
	.avatar {
		width: 40px;
		height: 40px;
		position: relative;
		display: block;
		z-index: 2;
		border-radius: 100%;
		-webkit-border-radius: 100%;
		-moz-border-radius: 100%;
		-ms-border-radius: 100%;
		background-color: $white-9;
		img {
			width: 40px;
			height: 40px;
			border-radius: 100%;
			-webkit-border-radius: 100%;
			-moz-border-radius: 100%;
			-ms-border-radius: 100%;
			background-color: $white-9;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
		}
	}
	.day {
		position: relative;
		display: block;
		text-align: center;
		color: silver;
		height: 20px;
		box-shadow: 0 0 0 1px rgba(243, 247, 253, 0.12), 0 8px 16px 0 rgba(243, 247, 253, 0.24);
		line-height: 38px;
		margin-top: 5px;
		margin-bottom: 20px;
		cursor: default;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
	}
	outline: 0;
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: end;
	justify-content: flex-end;
	min-height: 100%;
}

.chat-line {
	padding: 0;
	text-align: right;
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	+.chat-line {
		padding-top: 1rem;
	}
}

.chat-message {
	position: relative;
	display: inline-block;
	background-color: $blue;
	color: $white;
	font-size: .875rem;
	padding: .375rem .5rem;
	border-radius: 3px;
	white-space: normal;
	text-align: left;
	margin: 0 .5rem 0 2.5rem;
	line-height: 1.4;
	> :last-child {
		margin-bottom: 0 !important;
	}
	&:after {
		content: "";
		position: absolute;
		right: -5px;
		top: 7px;
		border-bottom: 6px solid transparent;
		border-left: 6px solid $blue;
		border-top: 6px solid transparent;
	}
	img {
		max-width: 100%;
	}
	p {
		margin-bottom: 1em;
	}
}

.chat-line-friend {
	-ms-flex-direction: row;
	flex-direction: row;
	+.chat-line-friend {
		margin-top: -.5rem;
		.chat-author {
			visibility: hidden;
		}
		.chat-message:after {
			display: none;
		}
	}
	.chat-message {
		background-color: $background;
		color: #3d4e67;
		margin-left: .5rem;
		margin-right: 2.5rem;
		&:after {
			right: auto;
			left: -5px;
			border-left-width: 0;
			border-right: 5px solid $background;
		}
	}
}