.panel-body-landing:hover>.table>tbody>tr>td {
	color: $white !important;
	-webkit-transition: all .3s ease;
}

.panel-heading-landing {
	background: #f7f7f7 !important;
	padding: 20px !important;
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	border: solid 2px #eef2f9 !important;
	border-bottom: none !important;
	text-align: center;
	margin-top: 20px;
}

.panel-heading-landing-box {
	background: #eef2f9 !important;
	color: $white !important;
	font-size: 16px !important;
	text-align: center;
	border-top: none !important;
}

.panel-title-landing {
	color: #eef2f9 !important;
	font-size: 35px;
	font-weight: 700;
}

.panel-body-landing {
	border: solid 2px #eef2f9 !important;
	border-top: none !important;
	border-bottom: none !important;
	text-align: center;
	background: $white;
}

.panel-footer-landing {
	border: solid 2px #eef2f9 !important;
	border-bottom-left-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
	border-top: none !important;
	text-align: center;
	background: #f7f7f7;
}

.panel.price {
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	-webkit-transition: all .3s ease;
	>.panel-heading {
		-moz-transition: all .3s ease;
		-o-transition: all .3s ease;
		-webkit-transition: all .3s ease;
	}
	h3 {
		margin-bottom: 0;
		padding: 20px 0;
	}
}

.panel-heading {
	padding: 10px 15px;
	background: $white;
}

.panel.price>.panel-heading {
	padding: 0;
	position: relative;
}

.price .list-group-item {
	border-bottom: 1px solid rgba(250, 250, 250, 0.5);
}

.panel.price {
	.list-group-item {
		&:last-child {
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
		&:first-child {
			border-top-right-radius: 0;
			border-top-left-radius: 0;
		}
	}
	margin-bottom: 1.5rem;
}

.price {
	.panel-footer {
		border-bottom: 0;
		background-color: $white;
	}
	&.panel-color>.panel-body {
		background-color: $white;
	}
}

.panel-body {
	padding: 15px;
	.lead {
		strong {
			font-size: 40px;
			margin-bottom: 0;
			font-weight: 400;
		}
		font-size: 20px;
		margin-bottom: 0;
		padding: 10px 0;
	}
}

.panel-footer {
	padding: 10px 15px;
	background-color: #f7f7f7;
	// border-top: 1px solid #e8ebf3;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}

.panel.price .btn {
	box-shadow: 0 -1px 0 rgba(50, 50, 50, 0.2) inset;
	border: 0;
}

.panel-group .panel {
	border-radius: 0;
	box-shadow: none;
	border-color: #e8ebf3;
}

.panel-default>.panel-heading {
	padding: 0;
	border-radius: 0;
	background-color: $background;
	border-color: #e8ebf3;
}

.panel-title {
	font-size: 14px;
	margin-bottom: 0;
	>a {
		display: block;
		padding: 15px;
		text-decoration: none;
	}
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
	border: 1px solid #e8ebf3;
}

.panel1 {
	border-width: 0 0 1px 0;
	border-style: solid;
	border-color: $white;
	background: 0 0;
	box-shadow: none;
	&:last-child {
		border-bottom: none;
	}
}

.panel-group1 {
	>.panel1:first-child .panel-heading1 {
		border-radius: 4px 4px 0 0;
	}
	.panel1 {
		border-radius: 0;
	}
}

.panel-body1 {
	padding: 10px;
}

.panel-title1 {
	font-size: 14px;
	margin-bottom: 0;
}

.panel-group1 .panel1+.panel1 {
	margin-top: 0;
}

.panel-heading1 {
	background-color: $white;
	border-radius: 0;
	border: none;
	color: $color;
	padding: 0;
}

.panel-group1 .panel-body {
	border: 1px solid #e8ebf3;
	border-top: 0;
}

.panel-title1 a {
	display: block;
	padding: 15px;
	position: relative;
	font-size: 16px;
	font-weight: 400;
	&.collapsed {
		color: $color;
	}
}

.panel-body1 {
	background: $white;
}

.panel1:last-child {
	.panel-body1 {
		border-radius: 0 0 4px 4px;
	}
	.panel-heading1 {
		border-radius: 0 0 4px 4px;
		transition: border-radius .3s linear .2s;
		&.active {
			border-radius: 0;
			transition: border-radius linear 0s;
		}
	}
}

.panel-title a.accordion-toggle {
	&:before {
		content: "\f068";
		padding: 0 10px 0 0;
		color: $white;
		font-family: FontAwesome;
		float: right;
	}
	&.collapsed:before {
		content: "\f067";
		padding: 0 10px 0 0;
		color: $white;
		font-family: FontAwesome;
		float: right;
	}
}

.panel-heading1 {
	a {
		&.collapsed:before {
			content: "\e9af";
			position: absolute;
			font-family: feather !important;
			right: 10px;
			top: 13px;
			font-size: 19px;
			transition: all .5s;
			transform: scale(1);
		}
		&:before {
			content: "\e994";
			position: absolute;
			font-family: feather !important;
			right: 10px;
			top: 13px;
			font-size: 19px;
			transition: all .5s;
			transform: scale(1);
		}
	}
	&.active a:before {
		content: ' ';
		transition: all .5s;
		transform: scale(0);
	}
}

.expanel-default {
	border: #ddd !important;
}

.expanel-secondary {
	border: #eef2f9 !important;
}

.expanel-success {
	border: $success !important;
}

.expanel-danger {
	border: $secondary6 !important;
}

.expanel-secondary>.expanel-heading {
	color: #7b838f !important;
	background-color: $background !important;
	border-color: #eef2f9 !important;
}

.expanel-success>.expanel-heading {
	color: $white !important;
	background-color: $success !important;
	border-color: $success !important;
}

.expanel-danger>.expanel-heading {
	color: $white !important;
	background-color: $secondary6 !important;
	border-color: $secondary6 !important;
}

.expanel-warning>.expanel-heading {
	color: $white !important;
	background-color: #ecd938 !important;
	border-color: #ecd938 !important;
}

.expanel-title {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	font-size: 16px !important;
	color: inherit !important;
}

.expanel {
	margin-bottom: 20px !important;
	background-color: $white !important;
	border: 1px solid #e8ebf3 !important;
	border-radius: 4px !important;
}

.expanel-default>.expanel-heading {
	background-color: $background !important;
	border-color: 1px solid #e8ebf3 !important;
}

.expanel-heading {
	padding: 10px 15px !important;
	border-bottom: 1px solid #e8ebf3;
	border-top-left-radius: 3px !important;
	border-top-right-radius: 3px !important;
}

.expanel-footer {
	padding: 10px 15px !important;
	background-color: $background !important;
	border-top: 1px solid #e8ebf3 !important;
	border-bottom-right-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
}

.expanel-body {
	padding: 15px !important;
}

.panel-title1 a.accordion-toggle {
	padding-right: 40px;
	line-height: 1.2;
	border: 1px solid #e8ebf3;
	border-radius: 3px 3px 0 0;
	&.collapsed {
		border-radius: 3px;
	}
}

.panel-collapse.collapse .panel-body {
	border-radius: 0 0 3px 3px;
}

.panel-rightside {
	.panel-title1 a {
		padding: 10px 15px;
		font-size: 14px;
	}
	.panel-heading1 a:before {
		font-size: 14px;
		right: 15px;
		top: 10px;
	}
}