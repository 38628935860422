.accordion .card {
	&:not(:first-of-type) {
		&:not(:last-of-type) {
			border-bottom: 0;
			border-radius: 0;
		}
		.card-header:first-child {
			border-radius: 0;
		}
	}
	&:first-of-type {
		border-bottom: 0;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
	&:last-of-type {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}

.demo-accordion p:last-child,
.panel-group1 p:last-child {
	margin-bottom: 0;
}

#bs-collapse .panel-heading1 {
	a:after {
		content: "\f01a";
		font-size: 24px;
		position: absolute;
		font-family: FontAwesome;
		right: 5px;
		top: 10px;
		transform: scale(0);
		transition: all .5s;
	}
	&.active a:after {
		content: "\f01a";
		transform: scale(1);
		transition: all .5s;
	}
}

#accordion .panel-heading1 {
	a:before {
		content: "\f01a";
		font-size: 24px;
		position: absolute;
		font-family: FontAwesome;
		right: 5px;
		top: 10px;
		transform: rotate(180deg);
		transition: all .5s;
	}
	&.active a:before {
		transform: rotate(0);
		transition: all .5s;
	}
}

.accordion#accordion {
	.card {
		margin-bottom: 15px;
	}
	h4 {
		&.collapsed:before {
			content: "\e9af";
			position: absolute;
			font-family: feather !important;
			right: 10px;
			top: 12px;
			font-size: 15px;
			transition: all .5s;
			transform: scale(1);
		}
		&:before {
			content: "\e994";
			position: absolute;
			font-family: feather !important;
			right: 10px;
			top: 12px;
			font-size: 15px;
			transition: all .5s;
			transform: scale(1);
		}
	}
	.card-header {
		padding: 0;
		padding-bottom: 0;
		min-height: 2.5rem;
		h4 {
			padding: 0.5rem 1rem;
			display: block;
			width: 100%;
		}
	}
}