

@media (min-width: 992px) {
	.app.sidenav-toggled {
		.app-content {
			margin-left: 0;
		}
		.app-sidebar {
			left: -230px;
		}
		.app-sidebar__overlay {
			visibility: hidden;
		}
	}
}

@media (max-width: 991px) {
	.app {
		overflow-x: hidden;
		.app-sidebar {
			left: -230px;
		}
		.app-sidebar__overlay {
			visibility: hidden;
		}
		&.sidenav-toggled {
			.app-content {
				margin-left: 0;
			}
			.app-sidebar {
				left: 0;
			}
			.app-sidebar__overlay {
				visibility: visible;
			}
		}
	}
}

.app-content {
	min-height: calc(100vh - 50px);
	margin-bottom: 0 !important;
	-webkit-transition: margin-left 0.3s ease;
	-o-transition: margin-left 0.3s ease;
	transition: margin-left 0.3s ease;
	.side-app {
		padding: 38px 30px 0 30px;
	}
}

.footer .container {
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	margin-right: auto;
	margin-left: auto;
}

@media (min-width: 992px) {
	.app-content {
		margin-left: 230px;
	}
}

@media (max-width: 991px) {
	.app-content {
		margin-top: 50px;
		min-width: 100%;
	}
}

@media (max-width: 480px) {
	.app-content {
		padding: 15px;
	}
}

@media print {
	.app-content {
		margin: 0;
		padding: 0;
		background-color: $white;
	}
}

.light-text {
	font-weight: 300 !important;
}

.semibold-text {
	font-weight: 600 !important;
}

.line-head {
	padding-bottom: 10px;
	border-bottom: 1px solid #ddd;
}


/*----- Componant: Top Navigation Bar ----- */

.app-header1 {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: $white;
	z-index: 1030;
	padding-right: 15px;
	padding: 10px 0;
	.input-icon.mt-1 {
		margin-top: 2px !important;
	}
}

@media (min-width: 992px) {
	.app-header1 {
		padding-right: 30px;
	}
}

@media print {
	.app-header1 {
		display: none;
	}
}

.app-header__logo {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	color: $white;
	text-align: center;
	padding: 0 15px;
	font-size: 26px;
	font-weight: 400;
	line-height: 50px;
	&:focus,
	&:hover {
		text-decoration: none;
	}
}

@media (min-width:992px) {
	.app-header__logo {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		display: block;
		width: 230px;
	}
}

.app-sidebar__toggle {
	padding: 0 20px;
	font-family: fontAwesome;
	color: #676b7b;
	margin-top: 3px;
	line-height: 2.4;
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;
	&:before {
		content: "\f0c9";
		font-size: 21px;
	}
	&:focus,
	&:hover {
		text-decoration: none;
		color: #676b7b;
	}
}

@media (max-width: 991px) {
	.app-sidebar__toggle {
		-webkit-box-ordinal-group: 0;
		-ms-flex-order: -1;
		order: -1;
	}
}

.app-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

@media (min-width: 992px) {
	.app-nav {
		-webkit-box-flex: 1;
		-ms-flex: 1 0 auto;
		flex: 1 0 auto;
	}
}

.app-nav__item {
	display: block;
	padding: 15px;
	line-height: 20px;
	color: #3e4b5b;
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;
	&:hover,
	&:focus {
		background: $black-1;
		color: #f6f6f6;
	}
}

.app-search {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-item-align: center;
	align-self: center;
	margin-right: 15px;
	padding: 10px 0;
}

@media (max-width: 480px) {
	.app-search {
		display: none;
	}
}

.app-search__input {
	border: 0;
	padding: 5px 10px;
	padding-right: 30px;
	border-radius: 2px;
	background-color: $white-8;
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;
	&::-webkit-input-placeholder,
	&:-ms-input-placeholder,
	&::-ms-input-placeholder,
	&::placeholder {
		color: $black-4;
	}
}

.app-search__button {
	position: absolute;
	right: 0;
	top: 10px;
	bottom: 10px;
	padding: 0 10px;
	border: 0;
	color: $black-8;
	background: none;
	cursor: pointer;
}

.app-notification {
	min-width: 270px;
}

.app-notification__title {
	padding: 8px 20px;
	text-align: center;
	background-color: rgba(0, 150, 136, 0.4);
	color: #333;
}

.app-notification__footer {
	padding: 8px 20px;
	text-align: center;
	background-color: #eee;
}

.app-notification__content {
	max-height: 220px;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-thumb {
		background: $black-2;
	}
}

.app-notification__item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 8px 20px;
	color: inherit;
	border-bottom: 1px solid #ddd;
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;
	&:focus,
	&:hover {
		color: inherit;
		text-decoration: none;
		background-color: #e0e0e0;
	}
}

.app-notification__message,
.app-notification__meta {
	margin-bottom: 0;
}

.app-notification__icon {
	padding-right: 10px;
}

.app-notification__message {
	line-height: 1.2;
}

.app-sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	padding-top: 60px;
	width: 230px;
	overflow: auto;
	background: $white;
	z-index: 10;
	box-shadow: 0 0 0 1px rgba(181, 201, 227, 0.12), 0 8px 16px 0 rgba(181, 201, 227, 0.24);
	color: #605e7e;
    -webkit-transition: left 450ms ease, width 450ms ease;
	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-thumb {
		background: $black-2;
	}
}

@media print {
	.app-sidebar {
		display: none;
	}
}

@media (max-width: 991px) {
	.app-sidebar__overlay {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 9;
	}
}

.app-sidebar__user {
	padding: 20px 10px;
	color: #e0e0e0;
	position: relative;
	.user-pro-body img {
		background: rgba(167, 180, 201, 0.2);
		z-index: 1000;
		position: inherit;
		padding: 4px;
		width: 60px;
		height: 60px;
	}
}

.user-info {
	color: #605e7e;
	text-align: center;
	h2 {
		font-size: 15px;
		margin: 0;
		font-weight: 600;
		margin-top: 10px;
	}
}

.clearfix::after {
	display: block;
	clear: both;
	content: "";
}

.user-info span {
	font-size: 12px;
	line-height: 30px;
}

.profile-img {
	position: absolute;
	top: -1px;
	right: 70px;
	display: block;
	border: 1px solid rgba(101, 84, 187, 0.2);
	border-radius: 50%;
	font-size: 10px;
	line-height: 22px;
	width: 22px;
	text-align: center;
	height: 24px;
	color: #605e7e;
	background: rgba(167, 180, 201, 0.2);
	&:hover {
		background: $white-6;
	}
}

.app-sidebar__user .user-pro-body {
	display: block;
	img {
		margin-bottom: 0 !important;
		display: block;
		margin: 0 auto 10px;
	}
	.u-dropdown {
		color: #97999f;
	}
	.user-info span.text-gray {
		color: #605e7e !important;
	}
	.dropdown-menu {
		right: 0;
		width: 80%;
		left: 0;
		margin: 0 auto;
		-webkit-transform: none !important;
		-ms-transform: none !important;
		transform: none !important;
		top: 85px !important;
		li a {
			padding-left: 5px !important;
			padding-right: 10px !important;
			padding-top: 10px !important;
			padding: 10px !important;
		}
	}
}

.sidebar-mini.sidenav-toggled .app-sidebar {
	.user-info,
	.profile-img,
	.app-sidebar-footer {
		display: none;
	}
	.app-sidebar__user {
		padding: 20px 0 !important;
	}
	.side-menu h3 {
		display: none;
		display: none;
	}
	.user-pro-body img {
		width: 3rem;
		height: 3rem;
	}
}

@media (max-width: 991px) {
	.sidebar-mini.sidenav-toggled .app-sidebar {
		.user-info {
			display: block;
		}
		.profile-img {
			display: block;
			right: 78px;
		}
		.app-sidebar-footer {
			display: block;
		}
		.app-sidebar__user {
			padding: 20px 0 !important;
			margin-left: 10px;
		}
	}
	.app-sidebar__user .user-pro-body img,
	.sidebar-mini.sidenav-toggled .app-sidebar .user-pro-body img {
		width: 3rem;
		height: 3rem;
	}
}

.app-sidebar__user-avatar {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	margin-right: 15px;
}

.app-sidebar__user-name {
	&.text-sm {
		font-size: 13px;
	}
	font-size: 17px;
	line-height: 1.3;
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	margin-bottom: 0;
}

.app-sidebar__user-designation {
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	margin-bottom: 0;
}

.side-menu {
	margin-bottom: 0;
	padding-bottom: 0px;
	h3 {
		padding-left: 15px;
		color: #605e7e;
		text-transform: uppercase;
		letter-spacing: .5px;
		font-weight: bold;
		font-size: 11px;
		margin-bottom: 15px;
		margin-top: 15px;
	}
	.slide li a {
		position: relative;
	}
	li ul li a.active:before {
		display: none;
	}
}

.side-menu__item {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 10px 35px 10px 30px;
	font-size: 14px;
	-webkit-transition: border-left-color 0.3s ease, background-color 0.3s ease;
	-o-transition: border-left-color 0.3s ease, background-color 0.3s ease;
	transition: border-left-color 0.3s ease, background-color 0.3s ease;
}

.app-sidebar .mCSB_draggerContainer {
	right: -11px;
}

.side-menu__icon {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: 25px;
}

.side-menu__label {
	white-space: nowrap;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}

.app-sidebar .side-menu__item.active {
	border-top: 1px solid #e9ecf3;
	border-bottom: 1px solid #e9ecf3;
	background: $background !important;
	color: $primary10 !important;
	&:hover {
		border-top: 1px solid #e9ecf3;
		border-bottom: 1px solid #e9ecf3;
		color: $white !important;
	}
}

.slide.is-expanded [data-bs-toggle='slide'] {
	border-bottom: 1px solid #e9ecf3;
}

.side-menu li {
	a {
		border-top: 1px solid #e9ecf3;
	}
	ul li a {
		border-top: 0;
		border-bottom: 0;
	}
}

.app-sidebar {
	.side-menu li a.active {
		background: none;
	}
	.slide-menu li.active>a {
		font-weight: 500;
	}
}

.slide-menu li ul {
	li {
		font-size: 12px !important;
		font-weight: 300;
		border-bottom: 1px solid rgba(0, 0, 0, 0.03);
	}
	a {
		padding: 10px 10px 10px 65px;
	}
}

.side-menu .slide-menu li a {
	font-size: 13px;
}

.slide ul li a {
	color: #605e7e;
	font-weight: 400;
}

.app-sidebar-footer {
	clear: both;
	display: block;
	color: $white;
	position: fixed;
	bottom: 0px;
	background: $white;
	width: 228px;
	z-index: 999;
	a {
		padding: 10px 0;
		text-align: center;
		width: 20%;
		color: $white;
		font-size: 15px;
		display: block;
		float: left;
		cursor: pointer;
		border-right: 1px solid $white;
		border-bottom: 1px solid $white;
		&:hover {
			background: rgba(255, 255, 255, 0.02);
		}
	}
}

.slide ul ul a {
	font-size: 0.9em !important;
	padding: 10px 10px 10px 45px !important;
}

.app-sidebar ul li a {
	color: #605e7e;
	font-weight: 500;
}

.slide.is-expanded {
	.slide-menu {
		// max-height: 300vh;
		display: block;
	}
	.angle {
		-webkit-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
	}
}

.slide-menu {
	// max-height: 0;
	display: none;
	overflow: hidden;
	-webkit-transition: max-height 0.3s ease;
	-o-transition: max-height 0.3s ease;
	transition: max-height 0.3s ease;
}

.slide-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 10px 20px 10px 65px;
	font-size: 13px;
	color: #3e4b5b;
}

.app-sidebar .side-menu .slide-menu li {
	border-bottom: 1px solid rgba(0, 0, 0, 0.03);
	&:last-child {
		border-bottom: 0;
	}
}

.slide-item .icon {
	margin-right: 5px;
}

.angle {
	-webkit-transform-origin: center;
	-ms-transform-origin: center;
	transform-origin: center;
	-webkit-transition: -webkit-transform 0.3s ease;
	transition: -webkit-transform 0.3s ease;
	-o-transition: transform 0.3s ease;
	transition: transform 0.3s ease;
	transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

@media (min-width: 992px) {
	.sidebar-mini.sidenav-toggled {
		.app-sidebar__user-name,
		.app-sidebar__user-designation,
		.angle {
			display: none;
		}
		.app-sidebar__user-avatar {
			width: 30px;
			height: 30px;
		}
		.app-content {
			margin-left: 67px;
		}
		.app-sidebar {
			left: 0;
			width: 70px;
			overflow: hidden;
			&:hover {
				overflow: visible;
			}
		}
		.side-menu__item {
			overflow: hidden;
			padding: 15px 28px !important;
			&:hover {
				overflow: visible;
				.side-menu__label {
					opacity: 1;
				}
				+.slide-menu {
					visibility: visible;
				}
			}
		}
		.slide {
			&:hover {
				.side-menu__item {
					overflow: visible;
					border-left-color: #766cc5;
					color: $primary10;
				}
				.side-menu__label {
					opacity: 1;
				}
				// .slide-menu {
				// 	max-height: 100vh;
				// 	opacity: 1;
				// 	visibility: visible;
				// 	z-index: 10;
				// }
			}
			.side-menu__label {
				border-bottom-right-radius: 0;
			}
		}
		.slide-menu {
			position: absolute;
			left: 50px;
			min-width: 180px;
			padding: 12px 0;
			opacity: 0;
			border-bottom-right-radius: 4px;
			z-index: 9;
			visibility: hidden;
			-webkit-transition: visibility 0.3s ease;
			-o-transition: visibility 0.3s ease;
			transition: visibility 0.3s ease;
		}
	}
}

.dropdown-menu {
	border-radius: 0;
	&.dropdown-menu-right {
		left: auto;
	}
}

.dropdown-item {
	.fa,
	.icon {
		vertical-align: middle;
	}
}

.app-title {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	background-color: #FFF;
	margin: -30px -30px 30px;
	padding: 20px 30px;
	-webkit-box-shadow: 0 1px 2px $black-1;
	box-shadow: 0 1px 2px $black-1;
	h1 {
		margin: 0;
		font-size: 24px;
		font-weight: 400;
	}
	p {
		margin-bottom: 0;
		font-style: italic;
	}
}

@media print {
	.app-title {
		display: none;
	}
}

@media (max-width: 480px) {
	.app-title {
		margin: -15px -15px 15px;
		padding: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
	}
}

@media (max-width: 480px) {
	.app-title p {
		display: none;
	}
}

.app-breadcrumb {
	margin-bottom: 0;
	text-align: right;
	font-weight: 500;
	font-size: 13px;
	text-transform: capitalize;
	padding: 0;
	text-align: left;
	padding: 0;
	background-color: transparent;
}

@media (max-width: 480px) {
	.app-breadcrumb {
		margin-top: 10px;
	}
}

.tile {
	position: relative;
	background: $white;
	border-radius: 3px;
	padding: 20px;
	-webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px $black-2;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px $black-2;
	margin-bottom: 30px;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

@media print {
	.tile {
		border: 1px solid #ddd;
	}
}

.sidenav-toggled .app-sidebar__user .avatar-md {
	width: 1.8rem;
	height: 1.8rem;
	line-height: 2rem;
	font-size: 1rem;
}

@media (min-width: 992px) {
	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
		width: 230px;
		transition: left 0.3s ease, width 0.3s ease;
	}

	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar__user {
		padding: 20px 10px !important;
	}

	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .user-pro-body img {
		width: 60px;
		height: 60px;
	}

	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu__item {
		position: relative;
		align-items: center;
		padding: 10px 35px 10px 30px !important;
		font-size: 14px;
		transition: border-left-color 0.3s ease, background-color 0.3s ease;
	}

	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .side-menu__label {
		opacity: 1;
		background: transparent;
	}

	.sidebar-mini.sidenav-toggled .slide.is-expanded .slide-menu.open {
		display: block;
		opacity: 1;
		visibility: visible;
		z-index: 10;
	}

	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .user-info,
	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .profile-img,
	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .app-sidebar-footer {
		display: block;
	}

	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar__user-name,
	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar__user-designation,
	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .angle {
		display: block;
	}

	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .slide-menu {
		position: relative;
		left: 0;
		min-width: 180px;
		padding: 0;
		opacity: 1;
		visibility: visible;
	}

	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .side-menu__label {
		display: block;
	}
	.sidebar-mini.sidenav-toggled .side-menu__label{
		display: none;
	}
	.sidebar-mini.sidenav-toggled{
		.toggle-logo{
			display: block;
		}
		.header-brand-img.light-logo{
			display: none;
		}
	}
	.sidebar-mini.sidenav-toggled.sidenav-toggled1{
		.toggle-logo{
			display: none;
		}
		.header-brand-img.light-logo{
			display: block;
		}
	}
	.app-header1.header{
		padding-right: 10px;
		padding-left: 228px;
		z-index: 9 !important;
	}
	.side-header {
		width: 230px;
		left: 0;
		right: 0;
		top: 0;
		position: fixed;
		z-index: 99;
		background: #fff;
	}
	.sidebar-mini.sidenav-toggled{
		.side-header{
			width: 70px;
		}
	}
	.sidebar-mini.sidenav-toggled.sidenav-toggled1{
		.side-header{
			width: 230px;
		}
	}
	.app.sidebar-mini.sidenav-toggled .side-menu__label {
		display: none;
	}
	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .side-menu__label {
		display: block !important;
	}
}

.slide.is-expanded .slide-menu {
    display: block;
}

.toggle-logo{
	display: none;
	width: 40px !important;
}

.child-sub-menu {
    display: none;
    padding: 0px;
    font-size: .8rem !important;
    margin-left: 1.5rem;
}
.child-sub-menu2 {
    display: none;
    padding: 0px;
    font-size: .8rem !important;
    margin-left: 1.5rem;
}
.sub-slide2.is-expanded .child-sub-menu2{
	display: block;
}
.sub-slide.is-expanded .child-sub-menu {
    display: block !important;
}

@media (max-width: 991px){
	.side-header {
		display: none !important;
	}
	.app-header1.header .logo-horizontal {
		display: block !important;
		left: 0;
	    right: 0;
       text-align: center;
	   margin: 0 auto;
	   width: 100%;
	}
}
.logo-horizontal {
    display: none;
}

// submenus //
.slide .slide-menu .sub-slide .sub-slide-menu {
	display: none;
}

.slide .slide-menu .sub-slide .sub-slide-menu.open {
	display: block;
}

.slide .slide-menu .sub-slide .sub-slide-menu .sub-slide2 .sub-slide-menu2 {
	display: none;
}

.slide .slide-menu .sub-slide .sub-slide-menu .sub-slide2 .sub-slide-menu2.open {
	display: block;
}

.sub-slide.is-expanded .sub-angle {
	transform: rotate(90deg);
}

.sub-slide2.is-expanded .sub-angle2 {
	transform: rotate(90deg);
}

.slide ul ul a {
    font-size: 13px !important;
    padding: 10px 20px 10px 80px !important;
}
.slide ul ul ul a {
    font-size: 13px !important;
    padding: 10px 10px 10px 100px !important;
}

@media(min-width: 992px){	
	.nav-search {
		margin-left: 6px;
	}
}