.header {
	padding-top: .75rem;
	padding-bottom: .75rem;
	width: 100%;
	z-index: 100;
}

body.fixed-header .header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1030;
}

@media print {
	.header {
		display: none;
	}
}

.header .mega-menu {
	width: 350px;
}

.header-brand {
	color: inherit;
	margin-right: 1rem;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
}

.app-header .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 200px;
	text-align: center;
	.header-brand-img {
		margin-left: 0;
	}
}

.app-header1 .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 215px;
	text-align: center;
	margin-top: -1.5px;
	.header-brand-img {
		margin-left: 0;
	}
}

.header-brand:hover {
	color: inherit;
	text-decoration: none;
}

.header-brand-img {
	height: 2.5rem;
	line-height: 2rem;
	vertical-align: bottom;
	margin-right: .5rem;
	width: auto;
}

.header-avatar {
	width: 2rem;
	height: 2rem;
	display: inline-block;
	vertical-align: bottom;
	border-radius: 50%;
}

.header-btn {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	font-size: 1rem;
}

.center-block {
	margin-right: auto;
	margin-left: auto;
	float: inherit !important;
}

.header-btn.has-new {
	position: relative;
	&:before {
		content: '';
		width: 6px;
		height: 6px;
		background: $secondary6;
		position: absolute;
		top: 4px;
		right: 4px;
		border-radius: 50%;
	}
}

.header-toggler {
	width: 2rem;
	height: 2rem;
	position: relative;
	color: $white;
	&:hover {
		color: $white;
	}
}

.header-toggler-icon {
	position: absolute;
	width: 1rem;
	height: 2px;
	color: inherit;
	background: currentColor;
	border-radius: 3px;
	top: 50%;
	left: 50%;
	margin: -2px 0 0 -.5rem;
	box-shadow: 0 5px currentColor, 0 -5px currentColor;
}

.header {
	.nav-item .badge,
	.nav-link .badge {
		position: absolute;
		top: 0;
		right: -1px;
		padding: .2rem .25rem;
		min-width: 1rem;
		font-size: 13px;
	}
}

#headerMenuCollapse {
	.nav-item .badge,
	.nav-link .badge {
		position: relative;
		min-width: 1rem;
		font-size: 10px;
		font-weight: 500;
	}
}

@media (max-width: 1300px) {
	.header-main .top-bar-right .custom li a span {
		display: none;
	}
}

.header-main .social-icons {
	float: right;
	li {
		color: #212529;
		margin-right: 35px;
		display: inline-block;
		list-style: none;
		font-size: 20px;
		a {
			color: #212529;
		}
	}
}

.header-search {
	padding: 1.5rem 1.5rem;
	background: $white;
	.header-icons {
		.header-icons-link {
			display: flex;
			margin-bottom: 0;
			li a {
				background: $background;
				width: 40px;
				height: 40px;
				border-radius: 50px;
				text-align: center;
				line-height: 2.5;
				margin-right: 5px;
				display: inline-block;
				border: 1px solid #eaeef9;
			}
			.header-icons-link1,
			.header-icons-link2 {
				width: 2.5rem;
				text-align: center;
				height: 2.5rem;
				font-size: 16px;
				position: relative;
			}
		}
		.header-icons-link1 .main-badge1 {
			position: absolute;
			top: -5px;
			right: -5px;
			text-align: center;
			font-size: 10px;
		}
		.header-icons-link.icons li {
			background: $white;
		}
	}
}

.top-bar {
	border-bottom: 1px solid #e8ebf3;
	background: $white;
}

.top-bar-left ul {
	margin-bottom: 0;
}

.top-bar {
	.top-bar-right {
		float: right;
	}
	.top-bar-left {
		display: flex;
		.socials li {
			display: inline-block;
			float: left;
			font-size: 15px;
			margin: 0px 0px;
			border-left: 1px solid rgba(255, 255, 255, 0.15);
			padding: 9px 15px;
			line-height: 1.7;
		}
		.contact li {
			margin: 10px 5px;
			display: inline-block;
			color: $color;
			padding: 9px 15px;
			border-right: 1px solid rgba(255, 255, 255, 0.15);
			line-height: 1.8;
		}
	}
	.top-bar-right {
		display: flex;
	}
}

.header-search {
	.header-inputs .input-group-text.searchicon {
		top: 10px;
		right: 25px;
		position: relative;
	}
	.header-search-logo {
		margin-right: 1rem;
	}
	.header-nav .nav-cart .icon-cart {
		i {
			font-size: 18px;
			color: #b3b7c1;
		}
		a {
			color: #b3b7c1;
		}
	}
}

.header-nav {
	display: flex;
}

.header-links {
	padding: 3px;
	li {
		margin: 5px;
		a {
			padding: 9px 18px;
			border-radius: 3px;
		}
	}
}

.header-main {
	.top-bar .contact {
		padding-left: 0;
		li {
			margin: 0;
		}
	}
	.top-bar-right .custom {
		display: flex;
		/* margin: 10px 0; */
		/* padding: 10px 0; */
		li {
			margin-right: 15px;
			border-right: 1px solid rgba(255, 255, 255, 0.15);
			padding-right: 15px;
			padding: 10px 15px;
			padding-left: 0;
			line-height: 1.7;
			&:last-child {
				margin-right: 0;
				padding-right: 0;
				border: 0;
			}
		}
	}
}

.ace-responsive-menu {
	margin-right: 1rem;
}

.header-main {
	.ace-responsive-menu li a {
		color: $color;
		&:hover {
			color: $white;
		}
		&:focus {
			color: $black;
		}
	}
	.post-btn {
		float: right;
		margin-left: auto;
	}
}

.header-menu1 {
	float: none !important;
}

@media (min-width: 992px) and (max-width: 1350px) {
	.header-menu1 .input-group {
		width: 140px;
	}
}

.header-slider-img {
	position: absolute;
	margin: 0 auto;
	text-align: center;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 25px;
	background: $black-2;
	.cat-item {
		.cat-img img {
			width: 50px;
			height: 50px;
			margin: 0 auto;
			padding: 7px;
		}
		.cat-desc {
			margin-top: 11px;
			color: #212529;
			h5 {
				font-weight: 600;
				text-transform: capitalize;
			}
		}
	}
}

.header-slider-img1 .cat-item {
	.cat-img img {
		width: 50px;
		height: 50px;
		margin: 0 auto;
		padding: 7px;
	}
	.cat-desc {
		margin-top: 11px;
		color: #212529;
		h5 {
			font-weight: 600;
			text-transform: capitalize;
		}
	}
}

.header-main-banner {
	position: absolute;
	top: 0;
	display: block;
	width: 100%;
	z-index: 99;
	.horizontal-main,
	.horizontalMenu>.horizontalMenu-list {
		background: 0 0;
	}
}

.header-style .horizontalMenu {
	float: inherit !important;
}

.top-bar {
	.select2-dropdown.select2-dropdown--below {
		width: 180px !important;
	}
	.select-country .select2-container {
		width: 150px !important;
	}
	&.bg-white {
		background: $white !important;
		.select2-container--default .select2-selection--single {
			.select2-selection__rendered {
				color: $color !important;
			}
			.select2-selection__arrow b {
				border-color: #b3b7c1 transparent transparent transparent;
			}
		}
	}
}

.header-text .item-search-tabs .form-control {
	border: 1px solid $white !important;
}

.top-header-dark .top-bar {
	background: #232323;
}

.top-header-light {
	.top-bar {
		background: $white !important;
	}
	.header-main .top-bar i {
		color: $color;
	}
	.top-bar .top-bar-left .socials li {
		border-left: 1px solid #eaeef9;
	}
	.header-main {
		.top-bar .contact {
			border-left: 1px solid #eaeef9 !important;
			li a {
				color: $color;
			}
		}
		.top-bar-right .custom li {
			a {
				color: $color;
			}
			border-right: 1px solid #eaeef9;
			&:last-child {
				border-right: 0;
			}
		}
	}
	.top-bar {
		.top-bar-left .contact li {
			border-right: 1px solid #eaeef9;
		}
		.select2-container--default .select2-selection--single {
			.select2-selection__arrow b {
				border-color: $color transparent transparent transparent;
			}
			.select2-selection__rendered {
				color: $color !important;
			}
		}
	}
}

.header {
	&.headerstyle1,
	&.headerstyle2,
	&.headerstyle3,
	&.headerstyle4 {
		background: $white !important;
	}
}

.side-header {
    margin: auto 0 !important;
    display: flex;
    border-bottom: 1px solid #e9edf4;
    border-right: 1px solid #e9edf4;
    align-items: center;
    transition: left 0.3s ease 0s, width 450ms ease 0s;
    height: 62px;
	position: fixed;
}
.side-header{
	.header-brand-img{
		margin: 0 auto !important;
		margin-bottom: 5px !important;
	}
}
.side-header{
	.header-brand-img.toggle-logo{
		margin-bottom: 0px !important;
	}
}

@media (min-width: 992px){
	.sidebar-mini.sidenav-toggled .app-header1.header{
		padding-left: 60px;
	}
}

.light-layout {
    display: none;
}

.app-sidebar{
	.side-header{
		.header-brand{
			margin: 0 auto;
		}
	}
}

@media(max-width: 375px){
	.top-bar{
		.top-bar-left .socials li {
			padding: 9px 12px;
		}
	}
	.header-main .top-bar-right .custom li{
		margin-right: 3px !important;
	}
}

@media (min-width: 992px){
	.sticky-wrapper.is-sticky .header-brand-img.dark-logo {
		 display: none;
	 }
	 .sticky-wrapper.is-sticky .header-brand-img.desktoplogo {
		 display: block !important;
		 padding: 1.25rem 0;
		 margin: 0;
		 float: left;
	 }
 
 }