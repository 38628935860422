.select2-lg {
	.select2-container .select2-selection--single {
		height: 2.875rem !important;
	}
	.select2-container--default .select2-selection--single .select2-selection__rendered {
		line-height: 45px !important;
	}
}

.select2-sm {
	.select2-container .select2-selection--single {
		height: 40px !important;
	}
	.select2-container--default .select2-selection--single {
		.select2-selection__rendered {
			line-height: 39px !important;
		}
		.select2-selection__arrow b {
			top: 48% !important;
		}
	}
}

select.form-control {
	&.select-lg {
		height: 46px !important;
		padding: 10px 16px !important;
		font-size: 17px !important;
		line-height: 1.3333333 !important;
		border-radius: 3px !important;
	}
	&.select-sm {
		height: 30px !important;
		padding: 0px 10px 1px 10px !important;
		line-height: 1 !important;
		border-radius: 3px !important;
	}
}

.select2-container--default {
	.select2-selection--single {
		background-color: $white;
		border: 1px solid #e8ebf3 !important;
		border-radius: 4px;
	}
	&.select2-container--focus .select2-selection--multiple {
		color: #3d4e67;
		background-color: $white;
		border-color: #4c8aec;
		outline: 0;
	}
	.select2-selection--multiple {
		background-color: $white;
		border: 1px solid #e8ebf3 !important;
		border-radius: 4px;
		cursor: text;
	}
	.select2-search--dropdown .select2-search__field {
		border: 1px solid #e8ebf3 !important;
	}
	.select2-selection--multiple .select2-selection__choice {
		background-color: $primary-01 !important;
		border: 1px solid $primary-09 !important;
		color:$primary-09;
	}
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: $primary-09 !important;
    background: transparent;
    border: 0px;
}



select.form-control:not([size]):not([multiple]) {
	height: 2.425rem;
}

.select2-container .select2-selection--single {
	height: 2.375rem;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #444;
	line-height: 38px !important;
	border-radius: 0;
}

select.form-control:focus::-ms-value {
	color: #3d4e67;
	background-color: $white;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: $color;
}

.index-search-select .select2 {
	width: 100% !important;
}

.select2-container .select2-selection--single {
	height: 100% !important;
}

.select-languages {
	color: $color;
	border-radius: 3px;
	&:focus {
		color: #3d4e67;
		background-color: $white;
	}
	.select2-container .select2-selection--single {
		height: 1.4rem !important;
		color: #b3b7c1 important;
		border-radius: 2px;
		background: 0 0 !important;
		border: 0 !important;
	}
	.select2-container--default .select2-selection--single {
		.select2-selection__rendered {
			line-height: 20.5px !important;
		}
		.select2-selection__arrow {
			top: -2px !important;
		}
	}
	.select2-container {
		width: 138px !important;
	}
	.select2-results {
		color: #b3b7c1 !important;
	}
}

.select-currency {
	.select2-container .select2-selection--single {
		height: 1.4rem !important;
		color: #b3b7c1 important;
		border-radius: 2px;
		background: 0 0 !important;
		border: 0 !important;
	}
	.select2-container--default .select2-selection--single {
		.select2-selection__rendered {
			line-height: 20.5px !important;
		}
		.select2-selection__arrow {
			top: -2px !important;
		}
	}
	.select2-container {
		width: 138px !important;
	}
	.select2-results {
		color: #b3b7c1 !important;
	}
}

.select-country {
	.select2-container {
		.select2-selection--single {
			.select2-selection__rendered {
				padding-left: 3px;
			}
			height: 1.4rem !important;
			color: #b3b7c1 important;
			border-radius: 2px;
			background: 0 0 !important;
			border: 0 !important;
		}
		width: 150px !important;
	}
	.select2-container--default .select2-selection--single {
		.select2-selection__rendered {
			line-height: 21px !important;
		}
		.select2-selection__arrow {
			top: -3px !important;
		}
	}
	.select2-results {
		color: #b3b7c1 !important;
	}
	.select2-container .select2-selection--single .select2-selection__rendered {
		padding-left: 3px;
	}
}

.select2-results__option {
	font-size: 13px;
}

.my-select .select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 100%;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 100%!important;
}

.select2-dropdown {
	border: 1px solid #eaeef9 !important;
}

.select2-container--default .select2-results__group {
	cursor: default;
	display: block;
	padding: 6px 2px 10px 2px;
}

.select2-dropdown {
	box-shadow: 2px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
}

.select2.select2-container--default {
	width: 0;
}
.app.sidebar-mini .select2-container--default .select2-selection--single .select2-selection__arrow{

	line-height: 2.2;
}
.select2-container .select2-search--inline .select2-search__field{
	margin-top: 20px !important;
}

.select2-container .select2-selection--single .select2-selection__rendered{
	padding-right: 30px !important;
}
.top-bar{
	.select-country{
		.select2-container--default .select2-selection--single .select2-selection__arrow b{
			top: 60% !important;
		}
	}
}